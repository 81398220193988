/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;
/*
0-600:          Phone
> - 900:        Tablet Portrait
> - 1200:       Tablet Landscape
[1200 - 1800]:  Normal Styles
1800+:          Big Desktops
*/
/* 
$breakpoint arguments:
phone, tab-port, tab-land, big-desktop
*/
@keyframes moveInLeft {
  0% {
    transform: translateX(-100px);
    opacity: 0; }
  80% {
    transform: translateX(10px); }
  100% {
    transform: translateX(0);
    opacity: 1; } }

@keyframes moveInRight {
  0% {
    transform: translateX(100px);
    opacity: 0; }
  80% {
    transform: translateX(-10px); }
  100% {
    transform: translateX(0);
    opacity: 1; } }

@keyframes moveInBottom {
  0% {
    transform: translateY(30px);
    opacity: 0; }
  100% {
    transform: translate(0);
    opacity: 1; } }

@keyframes moveInTop {
  0% {
    transform: translateY(-50px);
    opacity: 0; }
  100% {
    transform: translate(0);
    opacity: 1; } }

@keyframes moveInLeft-message {
  0% {
    opacity: 0;
    transform: translateX(-100px) skewX(14deg); }
  80% {
    transform: translateX(10px) skewX(14deg); }
  100% {
    opacity: 1;
    transform: translateX(0) skewX(14deg);
    animation-fill-mode: forwards, backwards; } }

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

html {
  font-size: 62.5%; }

body {
  box-sizing: border-box;
  background-color: black; }
  body::-webkit-scrollbar {
    width: 1em; }
  body::-webkit-scrollbar-track {
    border: 1px solid #fff;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); }
  body::-webkit-scrollbar-thumb {
    background-image: linear-gradient(to bottom, rgba(143, 33, 33, 0.7), rgba(27, 25, 155, 0.7), rgba(143, 33, 33, 0.7));
    border: 1px solid black;
    border-radius: 50px; }

a {
  text-decoration: none; }

::selection {
  background-color: #1b199b;
  color: #fff; }

.heading-primary {
  text-align: center;
  color: #fff;
  font-size: 7rem;
  margin: 0 2rem;
  font-family: 'Permanent Marker';
  animation-name: moveInTop;
  animation-delay: 0.2s;
  animation-fill-mode: forwards;
  animation-duration: 1.5s;
  animation-timing-function: ease-out;
  text-shadow: 0px 10px 33px #67a2ef, 0px -10px 33px #d11414; }
  @media only screen and (max-width: 768px) {
    .heading-primary {
      font-size: 4rem; } }

.footer {
  width: 100%; }
  .footer__content__social .youtube:hover {
    color: #b91c1c; }
  .footer__content__social .chess:hover {
    color: #047857; }
  .footer__content__social .github:hover {
    color: #1d4ed8; }
  .footer__content__social-icon:link, .footer__content__social-icon:visited {
    margin: 0 1.5rem;
    color: #fff;
    transition: all 0.3s ease;
    font-size: 3rem;
    text-decoration: none; }
    @media only screen and (max-width: 640px) {
      .footer__content__social-icon:link, .footer__content__social-icon:visited {
        font-size: 2rem;
        margin: 1rem; } }
  .footer__content__social-icon:hover, .footer__content__social-icon:active {
    color: #67a2ef; }
  .footer__content__copyright {
    color: #fff;
    font-size: 2rem; }
    @media only screen and (max-width: 640px) {
      .footer__content__copyright {
        font-size: 1rem;
        margin: 0.1rem; } }

.navigation__checkbox {
  display: none; }

.navigation__button {
  background-color: #fff;
  text-align: center;
  height: 7rem;
  width: 7rem;
  position: fixed;
  top: 6rem;
  right: 6rem;
  border-radius: 50%;
  z-index: 2000;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.1);
  cursor: pointer; }
  @media only screen and (max-width: 768px) {
    .navigation__button {
      top: 4rem;
      right: 4rem; } }
  @media only screen and (max-width: 640px) {
    .navigation__button {
      top: 3rem;
      right: 3rem; } }

.navigation__background {
  height: 6rem;
  width: 6rem;
  border-radius: 50%;
  position: fixed;
  top: 6.5rem;
  right: 6.5rem;
  background-image: radial-gradient(#67a2ef, #1b199b);
  z-index: 1000;
  transition: all 0.8s cubic-bezier(0.86, 0, 0.07, 1); }
  @media only screen and (max-width: 768px) {
    .navigation__background {
      top: 4.5rem;
      right: 4.5rem; } }
  @media only screen and (max-width: 640px) {
    .navigation__background {
      top: 3.5rem;
      right: 3.5rem; } }

.navigation__nav {
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1500;
  width: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.8s cubic-bezier(0.68, -0.55, 0.265, 1.55); }

.navigation__list {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  list-style: none;
  text-align: center;
  width: 100%; }

.navigation__item {
  margin: 1rem; }

.navigation__link:link, .navigation__link:visited {
  display: flex;
  justify-content: space-between;
  font-size: 3rem;
  font-weight: 300;
  padding: 1rem 2rem;
  min-width: 40%;
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  background-image: linear-gradient(120deg, transparent 0%, transparent 50%, #fff 50%);
  background-size: 220%;
  transition: all 0.2s;
  align-items: center;
  max-width: 333px; }
  .navigation__link:link span, .navigation__link:visited span {
    margin-right: 1.5rem;
    display: inline-block; }

.navigation__link:hover, .navigation__link:active {
  background-position: 100%;
  color: #67a2ef;
  transform: translateX(1rem); }

.navigation__checkbox:checked ~ .navigation__background {
  transform: scale(80); }

.navigation__checkbox:checked ~ .navigation__nav {
  width: 100%;
  opacity: 1;
  visibility: visible; }

.navigation__icon {
  position: relative;
  margin-top: 3.5rem; }
  .navigation__icon, .navigation__icon::before, .navigation__icon::after {
    width: 3rem;
    height: 2px;
    background-color: #999;
    display: inline-block; }
  .navigation__icon::before, .navigation__icon::after {
    content: '';
    position: absolute;
    left: 0;
    transition: all 0.2s; }
  .navigation__icon::before {
    top: -0.8rem; }
  .navigation__icon::after {
    top: 0.8rem; }

.navigation__button:hover .navigation__icon::before {
  top: -1rem; }

.navigation__button:hover .navigation__icon::after {
  top: 1rem; }

.navigation__checkbox:checked + .navigation__button .navigation__icon {
  background-color: transparent; }

.navigation__checkbox:checked + .navigation__button .navigation__icon::before {
  top: 0;
  transform: rotate(135deg); }

.navigation__checkbox:checked + .navigation__button .navigation__icon::after {
  top: 0;
  transform: rotate(-135deg); }

.warp {
  z-index: -1;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0; }

.login {
  position: absolute;
  top: 50%;
  left: 50%;
  min-height: 50vh;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 422px;
  border: rgba(255, 255, 255, 0.4) 1px solid;
  border-radius: 2px;
  background-color: transparent;
  overflow: hidden;
  z-index: -1; }
  .login::before {
    content: '';
    height: 300%;
    width: 300%;
    position: absolute;
    background: conic-gradient(#1b199b, #8f2121, #1b199b, #8f2121, #1b199b);
    left: -100%;
    top: -100%;
    animation: spin 1.5s infinite linear; }

@keyframes spin {
  100% {
    transform: rotate(-360deg); } }
  .login::after {
    content: '';
    background-color: #000;
    position: absolute;
    height: 95%;
    width: 95%;
    top: 2.5%;
    left: 2.5%; }
  .login__form {
    z-index: 3;
    width: 70%; }
    .login__form input {
      background-color: transparent;
      border: rgba(143, 33, 33, 0.4) 1px solid;
      border-radius: 3px;
      padding: 1rem;
      margin: 2rem 0;
      text-align: center;
      height: 4rem;
      width: 100%; }
    .login__form button {
      border: rgba(27, 25, 155, 0.4) 1px solid;
      border-radius: 3px;
      padding: 1rem;
      margin: 2rem 0;
      text-align: center;
      height: 4rem;
      width: 100%;
      color: #fff;
      transition: all 0.1s linear; }
      .login__form button:hover {
        transform: translateY(-4px);
        box-shadow: 0 1rem 2rem rgba(27, 25, 155, 0.4); }

.create-blog {
  padding: 1.5rem; }
  .create-blog__form label {
    color: #fff;
    margin: 0.5rem 0; }
  .create-blog__form input {
    background-color: #fff;
    border: rgba(27, 25, 155, 0.4) 1px solid;
    border-radius: 3px;
    padding: 1rem;
    text-align: left;
    height: 4rem;
    width: 100%; }
  .create-blog__preview-title {
    margin: 2rem 0;
    font-size: 3rem;
    color: #fff;
    font-weight: 700; }
  .create-blog__preview-submit {
    border: rgba(27, 25, 155, 0.4) 1px solid;
    border-radius: 3px;
    background-color: #fff;
    padding: 1rem;
    text-align: center;
    height: 4rem;
    width: 100%;
    max-width: 300px;
    color: #1b199b;
    transition: all 0.1s linear; }
    .create-blog__preview-submit:hover {
      transform: translateY(-4px); }

.edit-blog {
  padding: 1.5rem;
  border: solid 1px #fff;
  border-radius: 3px;
  margin: 4rem 0; }
  .edit-blog__form label {
    color: #fff;
    margin: 0.5rem 0; }
  .edit-blog__form input {
    background-color: #fff;
    border: rgba(27, 25, 155, 0.4) 1px solid;
    border-radius: 3px;
    padding: 1rem;
    text-align: left;
    height: 4rem;
    width: 100%; }
  .edit-blog__preview-title {
    margin: 2rem 0;
    font-size: 3rem;
    color: #fff;
    font-weight: 700; }
  .edit-blog__actions .button {
    cursor: pointer;
    border-radius: 3px;
    padding: 1rem;
    text-align: center;
    height: 4rem;
    width: 100%;
    max-width: 300px;
    color: #fff;
    transition: all 0.1s linear; }
    .edit-blog__actions .button:hover {
      transform: translateY(-4px); }
    .edit-blog__actions .button-update {
      border: rgba(103, 162, 239, 0.4) 1px solid;
      background-color: rgba(86, 67, 250, 0.3); }
    .edit-blog__actions .button-delete {
      border: rgba(143, 33, 33, 0.4) 1px solid;
      background-color: rgba(209, 20, 20, 0.3); }

.info-card {
  opacity: 0;
  transform: skewX(14deg);
  font-size: 2rem;
  padding: 4rem;
  margin-top: 4rem;
  text-align: center;
  max-width: 70%;
  color: #000;
  background-color: rgba(255, 255, 255, 0.85);
  transition: all 0.2s ease;
  box-shadow: 0 0.5rem 1rem white;
  animation-fill-mode: forwards;
  animation-name: moveInLeft-message;
  animation-delay: 0.3s;
  animation-duration: 1.1s;
  animation-timing-function: ease; }
  .info-card:hover {
    box-shadow: 0 0.5rem 1rem #67a2ef; }
  @media only screen and (max-width: 768px) {
    .info-card {
      font-size: 1.2rem; } }
  .info-card > * {
    transform: skewX(-14deg); }

.certification {
  min-height: 95vh; }
  .certification__content-certificates .certificate {
    background-image: linear-gradient(to right bottom, #67a2ef, #5643fa);
    max-width: 777px;
    color: #000;
    transition: all 0.2s ease-in-out; }
    .certification__content-certificates .certificate__img {
      width: 100%; }
    .certification__content-certificates .certificate__content {
      max-height: 80%;
      background-image: linear-gradient(to right bottom, #fff, #999); }
      .certification__content-certificates .certificate__content-title {
        line-height: 1;
        font-size: 2rem;
        margin-bottom: 0.3rem;
        color: #67a2ef;
        font-weight: 300; }
      .certification__content-certificates .certificate__content-subtitle {
        font-weight: 500;
        font-size: 1.2rem;
        color: #777;
        margin: 0.5rem 0; }
      .certification__content-certificates .certificate__content-body {
        margin: 1rem 0;
        border-left: 2px solid #67a2ef;
        padding-left: 0.5rem; }
      .certification__content-certificates .certificate__content-link {
        color: #1b199b; }
    .certification__content-certificates .certificate:hover {
      transform: translateY(-7px) scale(1.025);
      box-shadow: 0 0.5rem 1rem #67a2ef; }

.projects {
  min-height: 95vh; }
  .projects__content-projects .project-card {
    height: 100%;
    position: relative;
    max-width: 500px;
    color: #fff;
    border-radius: 3px;
    text-align: center;
    top: 0;
    left: 0;
    color: #000;
    transition: all 0.3s;
    position: relative;
    border-radius: 3px;
    background-color: #fff;
    overflow: hidden; }
    .projects__content-projects .project-card__side {
      font-size: 2rem;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      border-radius: 3px;
      overflow: hidden;
      box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15);
      height: auto;
      position: relative;
      box-shadow: none; }
      .projects__content-projects .project-card__side--front {
        background-color: #fff; }
      .projects__content-projects .project-card__side--back {
        background-image: linear-gradient(to right bottom, #5643fa, #1b199b);
        clip-path: polygon(0 25%, 100% 0, 100% 100%, 0 100%); }
    .projects__content-projects .project-card__tech svg {
      font-size: 3rem;
      margin: 0 1.5rem;
      color: #777; }
      @media only screen and (max-width: 640px) {
        .projects__content-projects .project-card__tech svg {
          margin: 0.5rem 1.5rem;
          font-size: 1.5rem; } }
    .projects__content-projects .project-card__top {
      height: 10rem;
      background-blend-mode: screen;
      -webkit-clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
      clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
      background-image: linear-gradient(to right bottom, #5643fa, #67a2ef); }
    .projects__content-projects .project-card__heading {
      font-size: 2.8rem;
      font-weight: 800;
      text-transform: uppercase;
      color: #fff;
      position: absolute;
      top: 2rem;
      right: 2rem;
      text-align: right;
      width: 75%; }
      .projects__content-projects .project-card__heading--span {
        padding: 1rem 1.5rem;
        -webkit-box-decoration-break: clone;
        box-decoration-break: clone;
        background-image: linear-gradient(to right bottom, rgba(86, 67, 250, 0.85), rgba(27, 25, 155, 0.85)); }
        @media only screen and (max-width: 640px) {
          .projects__content-projects .project-card__heading--span {
            padding: 0.5rem 1rem;
            font-size: 1.5rem; } }
    .projects__content-projects .project-card__details {
      padding: 7rem;
      margin: 0 auto;
      max-width: 88%; }
      @media only screen and (max-width: 640px) {
        .projects__content-projects .project-card__details {
          font-size: 1.3rem;
          margin: 2rem auto;
          padding: 1rem; } }
    .projects__content-projects .project-card__bottom {
      text-transform: uppercase;
      font-weight: 500;
      text-align: center;
      position: relative;
      top: 0%;
      left: 0%;
      transform: translate(0);
      width: 100%;
      padding: 4rem 2rem 2rem 2rem;
      transition: all 0.3s ease-in-out; }
      .projects__content-projects .project-card__bottom:hover {
        font-weight: 700;
        color: #fff;
        background-image: linear-gradient(to right, #5643fa, #67a2ef); }
    .projects__content-projects .project-card:hover {
      transform: translateY(-1rem);
      box-shadow: 0 1.5rem 2rem 1rem white; }

.header {
  min-height: 95vh;
  position: relative; }
  .header__content {
    min-height: 85vh;
    padding: 3rem;
    z-index: 3;
    color: #fff; }
  .header .messages {
    padding: 1rem;
    width: 50%; }
    @media only screen and (max-width: 1024px) {
      .header .messages {
        width: 80%; } }
    .header .messages__single {
      opacity: 0;
      transform: skewX(21deg);
      font-size: 3rem;
      padding: 1.5rem;
      margin: 2rem;
      text-align: center;
      width: 100%;
      color: #000;
      background-color: rgba(255, 255, 255, 0.85);
      transition: all 0.2s ease;
      box-shadow: 0 0.5rem 1rem white;
      animation-fill-mode: forwards; }
      @media only screen and (max-width: 768px) {
        .header .messages__single {
          font-size: 2rem; } }
      .header .messages__single > * {
        transform: skewX(-21deg); }
      .header .messages__single:nth-child(1) {
        animation-name: moveInLeft-message;
        animation-delay: 0.3s;
        animation-duration: 1.1s;
        animation-timing-function: ease; }
      .header .messages__single:nth-child(2) {
        animation-name: moveInLeft-message;
        animation-delay: 0.6s;
        animation-duration: 1.1s;
        animation-timing-function: ease; }
      .header .messages__single:nth-child(3) {
        animation-name: moveInLeft-message;
        animation-delay: 0.9s;
        animation-duration: 1.1s;
        animation-timing-function: ease; }
      .header .messages__single:nth-child(4) {
        animation-name: moveInLeft-message;
        animation-delay: 1.2s;
        animation-duration: 1.1s;
        animation-timing-function: ease; }

.blogposts {
  min-height: 95vh; }
  .blogposts__content-blogs {
    color: #000; }
    .blogposts__content-blogs .blog {
      border-radius: 3px;
      clip-path: polygon(0% 0%, 80% 0%, 90% 20px, 95% 0%, 100% 0%, 95% 40px, 100% 80px, 100% 100%, 0% 100%);
      width: 90%;
      margin: 2rem 0;
      padding: 1.5rem;
      background-image: linear-gradient(to right bottom, rgba(255, 255, 255, 0.85), rgba(153, 153, 153, 0.85)); }
      @media only screen and (max-width: 640px) {
        .blogposts__content-blogs .blog {
          clip-path: polygon(0% 0%, 80% 0%, 100% 33px, 100% 100%, 0% 100%); } }
      .blogposts__content-blogs .blog-title {
        padding: 1.5rem 0rem;
        margin-bottom: 2rem;
        font-size: 3rem;
        font-weight: 300; }
      .blogposts__content-blogs .blog-subtitle {
        font-weight: 700;
        color: #777;
        margin: 1rem 0;
        font-size: 2rem; }
      .blogposts__content-blogs .blog-content {
        font-size: 1.5rem;
        padding-bottom: 1rem;
        overflow-x: scroll; }
        .blogposts__content-blogs .blog-content::-webkit-scrollbar {
          width: 0.3rem;
          border-radius: 50px; }
        .blogposts__content-blogs .blog-content::-webkit-scrollbar-track {
          box-shadow: inset 0 0 1rem #5643fa;
          -webkit-box-shadow: inset 0 0 1rem #5643fa; }
        .blogposts__content-blogs .blog-content::-webkit-scrollbar-thumb {
          border-radius: 50px;
          background-image: linear-gradient(to right, #1b199b, #5643fa);
          border: 1px solid #5643fa; }

.dashboard__nav {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px; }
  .dashboard__nav-active {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-image: linear-gradient(to bottom, #67a2ef, #1b199b);
    color: #fff; }
  .dashboard__nav__item {
    padding: 1rem;
    font-size: 1.5rem;
    cursor: pointer; }

.dashboard__page {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-image: linear-gradient(to bottom, #1b199b, #67a2ef);
  box-shadow: 0 1.5rem 2rem rgba(86, 67, 250, 0.7); }
